import Seo from "gatsby-plugin-wpgraphql-seo";
import * as React from "react";
import CardsSection from "../components/CardsSection";
import GetInTouch from "../components/GetInTouch";
import ImageSectionsGroup from "../components/ImageSectionsGroup";
import Layout from "../components/Layout";
import NewsAndInsights from "../components/NewsAndInsights";
import Section from "../components/Section";
import Hero from "../componentsSections/Hero";
import { infinityTechPageTplData } from "../data/page-tpl-infinity-tech.data";
import { getCurrentPageSeo } from "../helpers/richContentHelpers";
import StatsSection from "../components/StatsSection";

const Page = ({ pageContext: { id } }) => {
  const seoPageData = getCurrentPageSeo(id);
  const pageData = infinityTechPageTplData(id);

  return (
    <>
      <Layout pageTitle={seoPageData.title} navbarVariant={pageData.hero.image ? "transparent" : undefined}>
        {/* SEO Metas */}
        <Seo post={seoPageData} />

        {/* Page head */}
        <Hero {...pageData.hero} textSize="big" />

        {/* Stats Section */}
        <Section className={"mb-0"}>
          <StatsSection {...pageData.stats}/>
        </Section>

        {/* Cards Sections */}
        <Section>
          <CardsSection {...pageData.cards} />
        </Section>

        {/* Image Sections */}
        <Section className="-mobile-full">
          <ImageSectionsGroup imageSections={pageData.imageSections} />
        </Section>

        {/* GetInTouch Section */}
        <Section className="-mobile-full">
          <GetInTouch pageTitle={seoPageData.title} />
        </Section>

        {/* News and insights Section */}
        <Section>
          <NewsAndInsights />
        </Section>
      </Layout>
    </>
  );
};

export default Page;
