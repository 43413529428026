import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { processCSSVars } from "../helpers/stylesHelpers";
import ButtonText from "./ButtonText";
import ContainerWide from "./ContainerWide";

const StatsSection = ({
  category,
  title,
  description,
  items,
  header,
  text,
  options,
  button,
}) => {
  return (
    <Container className="cards-section">
      {(category || title || description) && (
        <Row>
          <Col style={processCSSVars(options)}>
            {category && (
              <div className="cards-section__category">{category}</div>
            )}
            {title && <h2 className="cards-section__header">{title}</h2>}
            {description && (
              <div className="cards-section__text">{description}</div>
            )}
          </Col>
        </Row>
      )}

      <div className="stats-section">
        <div className="stats">
          {items?.map(({ item: { header, text } }) => (
            <div key={`${header}-${text}`} className="stat">
              <h2>{header}</h2>
              <p>{text}</p>
            </div>
          ))}
        </div>
        <div className="performance">
          <h2>{header}</h2>
          <p className="typo-24">{text}</p>
        </div>
      </div>

      {button?.link && (
        <ContainerWide className="d-flex justify-content-center get-in-touch-button mb-0 mt-5">
          <ButtonText variant={`${button.variant}`} {...button.link}>
            {button.link.title}
          </ButtonText>
        </ContainerWide>
      )}
    </Container>
  );
};

export default StatsSection;
